import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Community } from 'app/interfaces/community';
import { CommunityComponent } from 'app/pages/stores/community/community.component';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private fb: AngularFirestore
  ) { }



   //Crear post 
  public createPost(community: Community, infoUser: Users){
    const country = {
     country_name: infoUser.user_country
    }
   this.fb.collection('community').doc(`${community.country}`).set(country)
    this.fb.collection('community').doc(`${community.country}`).collection('publications').doc(`${community.publication_id}`).set(community);
  }
 

  //Obtiene todos los post
  public getPosts(infoUser: Users) {
    return this.fb.collection('community').doc(infoUser.user_country).collection('publications').snapshotChanges();
    // return this.fb.collection('users').doc(infoUser.user_email).collection('products').valueChanges();
  }
  public getPostsAll(infoUser: Users) {
    //console.log(infoUser.user_id)
    return this.fb.collection('community').doc(infoUser.user_country).collection('publications', ref => ref.where('user_id', '==', infoUser.user_id)).snapshotChanges();
    // return this.fb.collection('users').doc(infoUser.user_email).collection('products').valueChanges();
  }


  public getPostsAllAdmin(infoUser: Users) {
    //console.log(infoUser.user_id)
    return this.fb.collection('community').doc(infoUser.user_country).collection('publications').snapshotChanges();
    // return this.fb.collection('users').doc(infoUser.user_email).collection('products').valueChanges();
  }

    //Crea una nueva producto
    public createProduct(product: Product, infoUser: Users) {
      return this.fb.collection('users').doc(infoUser.user_email).collection('products').doc(product.product_id).set(product);
    }
    
  //Actualiza una nueva post
  public updatePost(comunidad: Community, infoUser: Users) {
    return this.fb.collection('community').doc(infoUser.user_country).collection('publications').doc(`${comunidad.publication_id}`).update(comunidad)
  }


  //Elimina una nueva post
  public deletePost(documentId: string, infoUser: Users) {
    this.fb.collection('community').doc(infoUser.user_country).collection('publications').doc(documentId).delete();
    //  return this.fb.collection('community').doc(infoUser.user_country).collection('publications').snapshotChanges();
  }

    //Elimina una nueva post
    public getPost(documentId: string, infoUser: Users) {
      return this.fb.collection('community').doc(infoUser.user_country).collection('publications').doc(documentId).snapshotChanges();
      //  return this.fb.collection('community').doc(infoUser.user_country).collection('publications').snapshotChanges();
    }


}
